<template>
    <div class="logs container">
        <breads :links="breadLinks"/>
        <PageHeader :title="$t('Event logs')"/>
        <div class="page-content">
            <TableMain
                :items="logsList"
                :fields="fieldsList"
                :hideActions="false"
                :header-columns="columns"
                :valuesFromRoute="true"
                :itemsMeta="logsMeta"
                @update="showLog"
                scroll="md"
                :preloader="preloader"
                :clearable="true"
                :hideModalInfo="false"
                :editIcon="false"
                @getItems="getLogs"
                :visibilityIcon="true"
                @getSearchProperty="getSearchProperty"
            />
        </div>
        <ModalComponent class="modal" :show="showModal" @close="closeModal">
            <div class="content" v-if="logForShow">
                <div class="title">
                    {{ logForShow.data.message }}
                </div>
                <div class="description">
                    <div class="item" v-if="logForShow.data.ip">
                        <span>IP</span> — {{ logForShow.data.ip }}
                    </div>
                    <div class="item" v-if="logForShow.data.agent">
                        <span>Agent</span> — {{ logForShow.data.agent }}
                    </div>
                    <!--                    <div class="item" v-if="logForShow.data.message">-->
                    <!--                        <span>Message</span> — {{logForShow.data.message}}-->
                    <!--                    </div>-->
                    <div class="item" v-if="logForShow.data.type">
                        <span>Type</span> — {{ logForShow.data.type }}
                    </div>
                    <div class="item" v-if="logForShow.data.link">
                        <span>Link</span> — <a :href="logForShow.data.link"><span data-v-2e00f08d=""
                                                                                  class="material-icons">download</span></a>
                    </div>
                    <div class="item" v-if="logForShow.data.date">
                        <span>Date</span> — {{ logForShow.data.date }}
                    </div>
                </div>
            </div>
<!--            <div class="code">-->
<!--                <pre>-->

<!--                </pre>-->
<!--            </div>-->
        </ModalComponent>
    </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import Breads from "@/components/Breads";
import TableMain from "../../components/ui/tables/TableMain";
import ModalComponent from "../../components/ui/ModalComponent";

export default {
    name: "Logs",
    components: {
        PageHeader,
        Breads,
        TableMain,
        ModalComponent
    },
    data() {
        return {
            breadLinks: [
                {
                    label: this.$t('Logs'),
                    to: {name: 'Logs'}
                }
            ],
            logsList: [],
            logsMeta: [],
            page: 1,
            type: null,
            showModal: false,
            columns: [
                {
                    id: 1,
                    label: 'ID',
                    sort: false,
                    attribute: "id",
                },
                {
                    id: 2,
                    label: this.$t('Name errors'),
                    sort: false,
                    attribute: "data.message",
                },
                {
                    id: 3,
                    label: this.$t('Network'),
                    sort: false,
                    attribute: "network.name",
                },
                {
                    id: 4,
                    label: this.$t('Cinema'),
                    sort: false,
                    attribute: "cinema",
                },
                {
                    id: 5,
                    label: this.$t('Date'),
                    sort: false,
                    attribute: "date",
                },
            ],
            fieldsList: [
                {by: 'id', type: "none"},
                {by: 'name', type: "none"},
                {by: 'network', type: "select", options: []},
                {by: 'cinema', type: "select", options: []},
                {by: 'date', type: "data", isActive: false},
            ],
            preloader: true,
            networkList: [],
            cinemaList: [],
            logForShow: null,
            searchNetwork: null,
            searchCinema: null,
            createdAt: null,
        }
    },
    computed: {
        notification() {
            return this.$store.getters.getListAllNotifications
        }
    },
    watch: {
        networkList() {
            this.updateFields()
        },
        cinemaList() {
            this.updateFields()
        }
    },
    methods: {
        showLog(v) {
            this.logForShow = this.logsList.find(el => el.id === v)
            this.showModal = true
        },
        async getLogs(params) {

            this.getSearchProperty(params)
            this.createdAt = null
            this.searchNetwork = this.$route.query.network
            this.searchCinema = this.$route.query.cinema

            if (this.$route.query.page) {
                this.page = this.$route.query.page
            }
            if (this.$route.query.date) {
                this.createdAt = this.$route.query.date
            }

            let resp = await this.api.logs.getLogs(this.page, this.type, this.searchNetwork, this.searchCinema, this.createdAt)
            this.logsList = []
            resp.data.forEach(log => {
                log.date = this.helper.getDate(log.created_at)
                this.logsList.push(log)
            })
            this.preloader = false
            this.logsMeta = resp
        },
        updateFields() {
            this.fieldsList = [
                {by: 'id', type: "none"},
                {by: 'name', type: "none"},
                {by: 'network', type: "select", options: this.networkList},
                {by: 'cinema', type: "select", options: this.cinemaList},
                {by: 'date', type: "data", isActive: false},
            ]
        },
        getSearchProperty(by) {
            if (by === 'network') {
                this.searchCinema = null
                this.$router.replace({'query': {'cinema': null}});
            } else if (by === 'cinema') {
                this.searchNetwork = null
                this.$router.replace({'query': {'network': null}});
            }
        },
        closeModal() {
            this.showModal = false
        },
        async getCinemaList() {
            let noPagination = {no_pagination: 1}
            let resp = await this.api.cinema.getCinemas(noPagination)
            resp.data.forEach(cinema => {
                this.cinemaList.push({
                    label: cinema.name,
                    id: cinema.id
                })
            })
        },
        async getNetworkList() {
            let noPagination = {no_pagination: 1}
            let resp = await this.api.network.getNetworks(noPagination)
            resp.data.forEach(network => {
                this.networkList.push({
                    label: network.name,
                    id: network.id
                })
            })
        }
    },
    created() {
        if (this.$route.name === 'LogsImport') {
            this.type = 'import'
        } else {
            this.type = 'integration'
        }

        this.getCinemaList()
        this.getNetworkList()
        this.getLogs()
    }
}
</script>

<style scoped lang="scss">
.logs {
    .modal {
        .title {
            font-size: 20px;
            font-weight: 600;
            color: #141213;
        }

        .code {
            width: 100%;
            height: 225px;
            border-radius: 2px;
            border: solid 1px #cad9f3;
            padding: 14px 22px;
            background-color: #F8FAFF;
            margin-top: 20px;
        }

        .description {
            .item {
                font-size: 18px;
                line-height: 1.5;
                color: #141213;
                margin-top: 18px;

                span {
                    font-weight: bold;
                }

                .material-icons {
                    font-size: 20px;
                    color: $color_darkblue;
                    position: relative;
                    top: 5px;
                }
            }
        }
    }


    ::v-deep {
        .table {
            thead {
                .table__labelTr {
                    text-transform: uppercase;
                    letter-spacing: 2px;

                    th:first-child {
                        //width: 7% !important;
                    }

                    th:nth-child(2) {
                        //width: 36% !important;
                    }

                    th:last-child {
                        width: 1%;
                    }
                }
            }

            tbody {
                color: #646e78;
            }
        }

        .modal {
            min-width: 100%;

            .modal__content {
                min-width: 67.5%;
                padding: 60px 113px;
            }
        }
    }
}
</style>
